import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { black } from '../../constants/colors';
import { useState } from 'react';
import { useLastLocation } from '../../hooks/useLastLocation';

type Props = {
  to: string | number;
};

export const BackButton = ({ to }: Props): JSX.Element => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <IconButton disabled={!to} LinkComponent={Link} to={to} aria-label='back-button' sx={{ paddingLeft: 0.5 }}>
      <ArrowBackIcon sx={{ color: black }} />
    </IconButton>
  );
};

/**
 * BackButton using Navigate state `backTo` property based on sender `location.pathname + location.search`
 * * The sender must use `navigate(myPath, { state: { backTo: location.pathname+location.search } })`
 * * The user must use `BackButtonNavState` instead of `BackButton` with `defaultTo` property being the sender `backTo`
 * @param defaultTo `/${string}` should be the same as the sender in case there is no link from
 * @returns `<IconButton />`
 */
export const BackButtonNavState = ({ defaultTo }: { defaultTo: `/${string}` }): JSX.Element => {
  const currentLocation = useLocation();
  const lastLocation = useLastLocation();
  const [state] = useState<{ backTo?: string } | undefined>(currentLocation.state);
  const back: number | string = _validBackTo(state?.backTo) ?? (lastLocation.lastLocation?.pathname ? -1 : defaultTo);
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <IconButton
      disabled={!back}
      LinkComponent={Link}
      to={back}
      state={state?.backTo ? state : undefined}
      aria-label='back-button'
      sx={{ paddingLeft: 0.5 }}>
      <ArrowBackIcon sx={{ color: black }} />
    </IconButton>
  );
};

const _validBackTo = (backTo: string | undefined): string | undefined => {
  if (typeof backTo === 'string' && backTo.startsWith('/') && backTo.length > 1) return backTo;
  return undefined;
};
