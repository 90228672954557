import { CSSProperties, forwardRef } from 'react';
import { inputSx } from '../../../validationNext/components/contractAgreement/sx';
import { backgroundErrorColor } from '../../constants/colors';
import { Input, InputProps, SxProps, Theme } from '@mui/material';

export type BaseInputProps<T extends string | number> = {
  disabled?: boolean;
  value?: number | null | undefined;
  onChange?: (newValue: T) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  name?: string;
  style?: CSSProperties;
  sx?: SxProps;
  suffix?: string;
  endAdornment?: JSX.Element;
};

export const StyledInput = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const { sx, ...rest } = props;
  return (
    <Input
      inputRef={ref}
      inputProps={{
        role: 'textbox',
        'aria-label': props.name,
        'aria-disabled': props.disabled,
        'aria-invalid': props.error ? 'true' : 'false',
      }}
      sx={{
        width: '100%',
        height: '100%',
        border: '1px solid transparent',
        ...inputSx,
        ...(props.error &&
          !props.disabled && {
            borderBottom: (theme) => `1px solid ${theme.palette.error.main}`,
            backgroundColor: backgroundErrorColor,
            color: (theme: Theme) => theme.palette.error.main,
          }),
        ':focus-within': {
          borderColor: 'black',
        },
        borderRadius: 0,
        '::before, :hover::before, ::after, :hover::after': { opacity: 0 },
        paddingLeft: '8px',
        paddingRight: '0px',
        '& .MuiInput-input': {
          paddingBottom: '1px',
        },
        ...sx,
      }}
      {...rest}
    />
  );
});
