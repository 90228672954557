import {
  DispatchingAlertSeverity,
  DispatchingAlertSeverityEnum,
  DispatchingAlertType,
  DispatchingAlertTypeEnum,
} from '../../Models/models';
import { orange, red } from '../../../common/constants/colors';
import {
  AssistantDirection,
  AttachFile as AttachmentIcon,
  Link as LinkIcon,
  LocalParking,
  Person as PersonIcon,
  PersonOff as IncompatibilityIcon,
  School as SchoolIcon,
  SvgIconComponent,
  Warehouse,
  WarningAmber as WarningIcon,
  TimerOff as TimerOffIcon,
} from '@mui/icons-material';
import { Crane as CraneIcon } from '../../../common/components/icons';
import { alpha, Box, SxProps } from '@mui/material';

export const getAlertIcon = (type: DispatchingAlertType, severity: DispatchingAlertSeverity) => {
  const color = severity == DispatchingAlertSeverityEnum.High ? red : orange;
  let Icon: SvgIconComponent;
  switch (type) {
    case DispatchingAlertTypeEnum.WorkSiteTraining:
    case DispatchingAlertTypeEnum.EquipmentTraining:
    case DispatchingAlertTypeEnum.ClientTraining:
      Icon = SchoolIcon;
      break;
    case DispatchingAlertTypeEnum.CraneFamily:
    case DispatchingAlertTypeEnum.CraneCapacity:
    case DispatchingAlertTypeEnum.EquipmentConflict:
    case DispatchingAlertTypeEnum.SecondaryCraneConfigurationUsedOnMainCrane:
      Icon = CraneIcon as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.EmployeeConflict:
    case DispatchingAlertTypeEnum.EmployeeUnavailability:
      Icon = PersonIcon as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.Incompatibility:
      Icon = IncompatibilityIcon as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.MissingBillingDocument:
      Icon = AttachmentIcon as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.LinkedServiceCallNotAssigned:
      Icon = LinkIcon as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.EquipmentRepairsStatus:
      Icon = Warehouse as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.EquipmentWorkSiteFixedStatus:
      Icon = LocalParking as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.EquipmentWorkSiteMovableStatus:
      Icon = AssistantDirection as SvgIconComponent;
      break;
    case DispatchingAlertTypeEnum.OvertimeForbidden:
      Icon = TimerOffIcon as SvgIconComponent;
      break;
    default:
      Icon = WarningIcon;
      break;
  }

  return (
    <Box sx={{ ...styles.iconBox, backgroundColor: alpha(color, 0.2) }}>
      <Icon sx={{ ...styles.icon, color: color }} />
    </Box>
  );
};

const styles: { [key: string]: SxProps } = {
  iconBox: {
    display: 'flex',
    padding: 1,
    borderRadius: 12,
  },
  icon: {
    width: 24,
    height: 24,
  },
};
