import { CompilablePremiumsEnum, NonCompilablePremiumsEnum } from '../../timesheet/enums/PremiumsEnum';

export const dailyTimeSheet = {
  button: {
    submit: 'Submit',
    linkTimeSheet_unlinked: 'Link Timesheet',
    linkTimeSheet_linked: 'Unlink Timesheet',
  },
  tooltip: {
    linkTimeSheet_submitted: "Cannot alter a submitted time sheet's number",
    linkTimeSheet_linked: 'Manual number already applied',
    manualNumber: 'Manual Number',
  },
  detail: {
    title: 'Time Sheet',
    manuallyCreated: 'Manually Created',
    exportButton: {
      title: 'Export',
      exportErrorPdf: 'Error exporting PDF',
      editingActive: 'Cannot export while editing',
    },
    submitButton: {
      editing: 'Cannot submit while editing',
      incomplete: 'Cannot submit while time sheet entries are incomplete',
    },
    reviewedBy: 'Reviewed by {{name}} ({{date}})',
    submitDailyTimeSheetModal: {
      title: 'Submit',
      content: 'Every time sheet entry of {{ name }} for the date of {{ date }} will be submitted.',
      cancel: 'Cancel',
      submit: 'Submit',
    },
    confirmTimeSheetEntryCompletionModal: {
      title: 'Warning',
      content: `Once the timesheet completed, the entry won't be editable on the mobile application.`,
      cancel: 'Cancel',
      save: 'Save',
    },
    times: 'Times',
    premiums: 'Premiums',
    indemnityType: 'Indemnity Type',
    comments: 'Comments',
    unlinked: 'Unlinked',
    attachments: 'Attachments',
    manualTimeSheetNumberModal: {
      title: 'Link Timesheet',
      content: `Enter the timesheet number to link to this WeLift timesheet entry. This new number will replace the existing one.`,
      label: 'Number',
      cancel: 'Cancel',
      link: 'Link',
    },
  },
  information: {
    client: 'Client',
    siteName: 'Site',
    siteAddress: 'Site Address',
    contractAgreement: 'Contract Agreement',
    workType: 'Work Type',
    unitNumber: 'Equipment',
    gtNumber: 'GT Number',
    contractAgreementCompletedAt: 'CA Completed at',
  },
  timesheetType: {
    linked: 'Linked',
    mixed: 'Mixed',
    nonLinked: 'Non-Linked',
  },
  timeSheetEntryStatus: {
    validated: 'Validated',
    submitted: 'Submitted',
    completed: 'Finished',
    ongoing: 'Ongoing',
    submissionError: 'Error',
  },
  premiumsList: {
    [CompilablePremiumsEnum.ManTimePremium]: 'Man time',
    [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
    [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
    [CompilablePremiumsEnum.ManTransportPremium]: 'Man transport',
    [CompilablePremiumsEnum.PreparationPremium]: 'Preparation',
    [NonCompilablePremiumsEnum.MealPremium]: 'Meal',
    [NonCompilablePremiumsEnum.TaxisPremium]: 'Taxis',
    [NonCompilablePremiumsEnum.ParkingPremium]: 'Parking',
    [NonCompilablePremiumsEnum.TravellingExpensesPremium]: 'Traveling expenses',
    [NonCompilablePremiumsEnum.PensionPremium]: 'Pension',
    [NonCompilablePremiumsEnum.CarAllowancePremium]: 'Car Allowance',
  },
};
