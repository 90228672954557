import { TFunction } from 'i18next';
import { toNumber } from 'lodash';
import { MultiSelectChipInputItemOption } from '../../common/components/inputs/MultiSelectChipInput';
import { CraneOperatorKindNumberEnum } from '../../dispatch/Models/models';
import { CraneOperatorKind } from '../../validationNext/models/models';

export function buildEnumItems(t: TFunction, enumObj: Record<string, string | number>, enumName: string): MultiSelectChipInputItemOption[] {
  return Object.values(enumObj).map((key) => ({
    value: String(key),
    label: t(`${enumName}.${key}`),
  }));
}

export function buildWorkTypeEnumItems(
  t: TFunction,
  enumObj: Record<string, string | number>,
  enumName: string,
): MultiSelectChipInputItemOption[] {
  return buildEnumItems(t, enumObj, enumName).sort((a, b) => {
    const regexp = /^(\d+)/;
    const aNumber = toNumber(a.label.match(regexp)?.[0] ?? '0');
    const bNumber = toNumber(b.label.match(regexp)?.[0] ?? '0');
    return aNumber - bNumber;
  });
}

export function buildCraneOperatorKindEnumItems(
  t: TFunction,
  enumObj: Record<string, string | number>,
  enumName: string,
): MultiSelectChipInputItemOption[] {
  return buildEnumItems(t, enumObj, enumName)
    .map((item) => ({
      ...item,
      label: `${CraneOperatorKindNumberEnum[item.value as CraneOperatorKind]} - ${item.label}`,
    }))
    .sort((a, b) => {
      const aNumber = CraneOperatorKindNumberEnum[a.value as CraneOperatorKind];
      const bNumber = CraneOperatorKindNumberEnum[b.value as CraneOperatorKind];
      return aNumber - bNumber;
    });
}
