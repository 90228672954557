import { Location, useNavigationType } from 'react-router-dom';
import { createContext } from 'react';
import { BehaviorSubject } from 'rxjs';

interface CustomLastLocationType extends Location {
  pathname: string;
  type?: ReturnType<typeof useNavigationType>;
}

export type LastLocationType = CustomLastLocationType | null;

export interface LastLocationContextType {
  lastLocation: LastLocationType;
  lastLocationSubject: BehaviorSubject<LastLocationType>;
  setLastLocation: (location: LastLocationType) => void;
}

export const initialState: LastLocationType = null;

const LastLocationContext = createContext<LastLocationContextType>({
  lastLocation: initialState,
  lastLocationSubject: new BehaviorSubject<LastLocationType>(initialState),
  setLastLocation: () => {},
});

export { LastLocationContext };
