import { CompilablePremiumsEnum, NonCompilablePremiumsEnum } from '../../timesheet/enums/PremiumsEnum';

export const dailyTimeSheet = {
  button: {
    submit: 'Soumettre',
    linkTimeSheet_unlinked: 'Lier CT papier',
    linkTimeSheet_linked: 'Délier CT papier',
  },
  tooltip: {
    linkTimeSheet_submitted: "Impossible de modifier le numéro d'une carte de temps soumise",
    linkTimeSheet_linked: 'Numéro manuel déjà appliqué',
    manualNumber: 'Numéro manuel',
  },
  detail: {
    title: 'Carte de temps',
    manuallyCreated: 'Créé manuellement',
    exportButton: {
      title: 'Exporter',
      exportErrorPdf: "Erreur lors de l'exportation du PDF",
      editingActive: "Impossible d'exporter en cas de modification",
    },
    submitButton: {
      editing: 'Impossible de soumettre en cas de modification',
      incomplete: 'Impossible de soumettre tant que les entrées de la carte de temps ne sont pas complètes',
    },
    reviewedBy: 'Révisé par {{name}} ({{date}})',
    submitDailyTimeSheetModal: {
      title: 'Soumettre',
      content: 'Chaque entrée de la carte de temps de {{ name }} pour la date du {{ date }} sera soumise.',
      cancel: 'Annuler',
      submit: 'Soumettre',
    },
    confirmTimeSheetEntryCompletionModal: {
      title: 'Avertissement',
      content: `Une fois la carte de temps terminée, l'entrée de temps ne sera plus modifiable sur l'application mobile.`,
      cancel: 'Annuler',
      save: 'Enregistrer',
    },
    times: 'Temps',
    premiums: 'Indemnités',
    indemnityType: 'Type d’indemnité',
    comments: 'Commentaires',
    unlinked: 'Non lié',
    attachments: 'Pièces jointes',
    manualTimeSheetNumberModal: {
      title: 'Lier une carte de temps',
      content: `Entrez le numéro de la carte de temps à lier à cette entrée de carte de temps WeLift. Ce nouveau numéro remplacera l'ancien.`,
      label: 'Numéro',
      cancel: 'Annuler',
      link: 'link',
    },
  },
  information: {
    client: 'Client',
    siteName: 'Site',
    siteAddress: 'Adresse du site',
    contractAgreement: 'Contrat de service',
    workType: 'Type de travail',
    unitNumber: 'Equipement',
    gtNumber: 'Numéro GT',
    contractAgreementCompletedAt: 'CS Terminé le',
  },
  timesheetType: {
    linked: 'Liée',
    mixed: 'Mixte',
    nonLinked: 'Non liée',
  },
  timeSheetEntryStatus: {
    validated: 'Validée',
    submitted: 'Soumise',
    completed: 'Terminée',
    ongoing: 'En cours',
    submissionError: 'Erreur',
  },
  premiumsList: {
    [CompilablePremiumsEnum.ManTimePremium]: 'Temps homme',
    [CompilablePremiumsEnum.NoBreakTimesPremium]: 'No Break',
    [CompilablePremiumsEnum.NoLunchTimesPremium]: 'No Lunch',
    [CompilablePremiumsEnum.ManTransportPremium]: 'Transport homme',
    [CompilablePremiumsEnum.PreparationPremium]: 'Préparation',
    [NonCompilablePremiumsEnum.MealPremium]: 'Repas',
    [NonCompilablePremiumsEnum.TaxisPremium]: 'Taxis',
    [NonCompilablePremiumsEnum.ParkingPremium]: 'Stationnement',
    [NonCompilablePremiumsEnum.TravellingExpensesPremium]: 'Frais de déplacement',
    [NonCompilablePremiumsEnum.PensionPremium]: 'Pension',
    [NonCompilablePremiumsEnum.CarAllowancePremium]: 'Utilisation du Véhicule',
  },
};
