import { darken, Stack, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { createContext, PropsWithChildren, RefObject, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackButtonNavState } from '../../../common/components/button/BackButton';
import { LoadingButton } from '../../../common/components/button/LoadingButton';
import { AxiosErrorPage } from '../../../common/components/page/AxiosErrorPage';
import { FormBinderRef, useFormBinderRef } from '../../../common/hooks/useFormBinderRef';
import { BlockerProvider } from '../../../common/provider/BlockerProvider';
import { Path } from '../../../paths';
import { WorkKindsPageEnum } from '../WorkKindsPage';
import { defaultPrimaryColor } from '../../../common/constants/colors';

type ChildProps = {
  actionsRef: RefObject<FormBinderRef>;
  submit: () => Promise<boolean>;
};

const HeaderContext = createContext<ChildProps>({
  actionsRef: { current: null },
  submit: () => Promise.resolve(false),
});

export const useHeaderPageContext = () => {
  return useContext(HeaderContext);
};

type Props = PropsWithChildren<{ isLoading?: boolean; error?: string | null; onDelete?: () => void }>;

function AutomationRuleDetailHeader({ children, error, onDelete }: Props) {
  const { t } = useTranslation('workKinds');

  const { ref: formBinderRef, formSubmit, formSaveDisabled, formSaveLoading } = useFormBinderRef();
  const { id } = useParams();
  const isEdit = !!id;

  if (error && isAxiosError(error)) {
    return <AxiosErrorPage error={error} />;
  }

  return (
    <>
      <Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Stack direction='row' justifyContent='space-between' flex={1} spacing={2}>
            <Stack direction='row' spacing={1} alignItems='center' aria-label='header-page'>
              <BackButtonNavState defaultTo={`/${Path.WorkKindsPage}?tab=${WorkKindsPageEnum.Automatisation}`} />
              <Stack direction='row' spacing={1} alignItems='center'>
                {isEdit ? (
                  <Typography variant='h1'>{t('automationRuleForm.editAutomation')}</Typography>
                ) : (
                  <Typography variant='h1'>{t('automationRuleForm.newAutomation')}</Typography>
                )}
              </Stack>
            </Stack>
            <Stack direction='row' flexWrap='wrap' justifyContent='flex-end' spacing={1} alignItems='center'>
              {isEdit && (
                <LoadingButton
                  data-testid='save-button'
                  isLoading={formSaveLoading}
                  variant='contained'
                  color='primary'
                  sx={{
                    textTransform: 'uppercase',
                    minWidth: 48,
                    backgroundColor: darken(defaultPrimaryColor, formSaveDisabled ? 0.2 : 0),
                    color: formSaveDisabled ? darken(defaultPrimaryColor, 0.5) : 'white',
                    '&:hover': {
                      backgroundColor: darken(defaultPrimaryColor, formSaveDisabled ? 0.2 : 0.3),
                    },
                  }}
                  disabled={formSaveDisabled}
                  onClick={() => onDelete?.()}>
                  {t('button.delete', { ns: 'common' })}
                </LoadingButton>
              )}
              <LoadingButton
                data-testid='save-button'
                isLoading={formSaveLoading}
                variant='contained'
                color='secondary'
                sx={{
                  textTransform: 'uppercase',
                  minWidth: 48,
                }}
                disabled={formSaveDisabled}
                onClick={async () => {
                  await formSubmit('save');
                }}>
                {t(id ? 'button.save' : 'button.create', { ns: 'common' })}
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <BlockerProvider handleSave={() => formSubmit('save')}>
        <HeaderContext.Provider value={{ actionsRef: formBinderRef, submit: () => formSubmit('save') }}>{children}</HeaderContext.Provider>
      </BlockerProvider>
    </>
  );
}

export default AutomationRuleDetailHeader;
