import { isEqual } from 'lodash';
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defer, repeat } from 'rxjs';

const _getValueFromSessionStorage = async <T>(key: string): Promise<T | undefined> => {
  if (!key || typeof window === 'undefined') return;

  try {
    const item = window.sessionStorage.getItem(key);
    if (item && item !== 'undefined') return JSON.parse(item);
  } catch (error) {
    console.error(error);
  }
};

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  autoReload: boolean = true,
): readonly [T, (value: T | ((val: T) => T)) => void, boolean] {
  const previousStoredValue = useRef<T | undefined>(undefined);
  const [value, setValue] = useState<T>(initialValue);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!key) return;
    let observable = defer(() => _getValueFromSessionStorage<T>(`${key}`));

    if (autoReload) observable = observable.pipe(repeat({ delay: 2000 }));
    const subscriber = observable.subscribe((storedValue: T | undefined) => {
      setLoaded((prev) => (!prev ? true : prev));
      const _value = storedValue ?? initialValue;
      if (value !== undefined && !isEqual(storedValue, previousStoredValue.current)) {
        previousStoredValue.current = _value;
        setValue(_value);
      }
    });
    return () => {
      if (subscriber) {
        subscriber.unsubscribe();
      }
    };
    // prevent 'value' changes to unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, key, autoReload]);

  const handleSetValue = useCallback(
    async (newValue: SetStateAction<T>) => {
      if (typeof window === 'undefined' || !key) return;
      setValue((prev) => {
        const next = typeof newValue === 'function' ? (newValue as (prevState: T) => T)(prev) : newValue;
        window.sessionStorage.setItem(key, JSON.stringify(next));
        previousStoredValue.current = next;
        return next;
      });
    },
    [key],
  );

  return useMemo(() => [value, handleSetValue, loaded] as const, [handleSetValue, value, loaded]);
}
